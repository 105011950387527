<template>
  <ServiceComboFormPart
    :service-types="serviceTypes"
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  windowFramesPlasticConsumerBE,
  windowFramesPlasticCorporateBE,
  windowFramesWoodConsumerBE,
  windowFramesWoodCorporateBE,
  windowFramesAluminiumConsumerBE,
  windowFramesAluminiumCorporateBE,
} from 'chimera/windowFrames/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey,
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Particulier', 'Particulier', consumerKey),
        new Selectable('Professionnel', 'Professionnel', corporateKey),
      ],

      serviceSelections: [
        new Selectable('Cadres en PVC', 'Cadres en PVC', 'Cadres en PVC', {
          consumer: windowFramesPlasticConsumerBE,
          corporate: windowFramesPlasticCorporateBE,
        }),
        new Selectable('Cadres en bois', 'Cadres en bois', 'Cadres en bois', {
          consumer: windowFramesWoodConsumerBE,
          corporate: windowFramesWoodCorporateBE,
        }),
        new Selectable(
          'Cadres en aluminium',
          'Cadres en aluminium',
          'Cadres en aluminium',
          {
            consumer: windowFramesAluminiumConsumerBE,
            corporate: windowFramesAluminiumCorporateBE,
          },
        ),
      ],
    }
  },
}
</script>
